// THIS FILE MUST MATCH /frontend2/account/src/profile/components/themes.ts
export interface ITheme {
    name: string;
    displayName: string;
    theme: {
        [key: string]: string;
    };
}

export interface Themes {
    [key: string]: ITheme;
}

export const DEFAULT_THEME = 'light-gray';

const themes: Themes = {
    'light-gray': {
        name: 'light-gray',
        displayName: 'Light - Gray',
        theme: {
            '--color-scheme': 'light',

            '--color-base-font-colour': '#444',
            '--color-button-text': '#666',
            '--color-border-1': '#D0D4DA',
            '--color-border-2': '#D0D4DA',
            '--color-grey-bg-1': '#EEEFF0',
            '--color-grey-bg-2': '#F5F6F6',
            '--color-white': '#FFF',
            '--color-white-text': '#FFF',
            '--color-white-text-disabled': '#BBB',
            '--color-white-text-disabled-2': 'rgba(255, 255, 255, 0.8)',

            '--color-verint-logo-background': '#0075E4',
            '--color-verint-logo-foreground': '#FFF',

            '--color-verint-blue': '#0075E4',
            '--color-green-light': '#54B657',
            '--color-green-dark': '#459E45',
            '--color-highlight': '#EDF6FF',
            '--color-brown': '#7B6E1A',
            '--color-yellow': '#FFE463',
            '--color-yellow-pale': '#FEF9E6',

            '--color-assistant-outline': '#D1BDD4',
            '--color-assistant-fill': '#EFE0F1',
            '--color-assistant-info': '#8A42A7',
            '--color-handover-bot': '#1F8563',
            '--color-bot-stroke': '#9FBBAE',
            '--color-bot-fill': '#D9E8E1',
            '--color-handover-agent': '#274D5E',

            '--color-orange': '#EF540D',
            '--color-information': '#49B3DC',
            '--color-dialog-background': 'rgba(0, 29, 44, 0.6)',
            '--color-warning-yellow': '#F2BD25',

            '--color-grey-1': '#EEE',
            '--color-grey-2': '#DDD',
            '--color-grey-3': '#CCC',
            '--color-grey-4': '#BBB',
            '--color-grey-5': '#676767',

            '--color-blue-highlight-1': '#E2F1FF',
            '--color-blue-lowlight-1': '#A5BCD5',
            '--color-blue-lowlight-2': '#1B5CBF',
            '--color-blue-focus': '#3395FF',

            '--color-grey-button': '#F8FAFA',
            '--color-grey-hover': '#E7F1F1',
            '--color-grey-active': '#DDE5E5',
            '--color-grey-disabled': '#EEE',

            '--color-blue-hover': '#0062BF',
            '--color-blue-active': '#00498F',
            '--color-blue-disabled': '#89B8E4',

            '--color-red': '#D02E28',
            '--color-red-hover': '#B82E29',
            '--color-red-active': '#9E2E2A',
            '--color-red-disabled': '#DC9B99',

            '--color-green': '#0C874B',
            '--color-green-hover': '#0A6C3C',
            '--color-green-active': '#07512D',
            '--color-green-disabled': '#EEE',

            '--color-green-light-hover': '#65C566',
            '--color-green-light-border': '#459E45',
            '--color-green-light-disabled': '#A9DAAB',

            '--color-orange-hover': '#E24700',
            '--color-orange-active': '#CE4100',
            '--color-orange-disabled': '#F7A986',

            '--color-dark-blue': '#224A5C',
            '--color-dark-blue-hover': '#163D4E',
            '--color-dark-blue-active': '#103140',
            '--color-dark-blue-disabled': '#A7B7BE',

            '--color-button-yellow': '#D9A000',
            '--color-button-yellow-hover': '#C08D00',
            '--color-button-yellow-active': '#AB7E00',
            '--color-button-yellow-disabled': '#F0D999',

            '--color-purple': '#8A42A7',
            '--color-purple-hover': '#7F309F',
            '--color-purple-active': '#752794',
            '--color-purple-disabled': '#95839C',

            '--color-tag-red': '#FDD5DA',
            '--color-tag-orange': '#FEEACD',
            '--color-tag-yellow': '#FEFBCB',
            '--color-tag-green': '#DAF8D0',
            '--color-tag-aqua': '#CCFAF4',
            '--color-tag-blue': '#D4E1FF',
            '--color-tag-pink': '#F7DBFC',

            '--color-login-bg': '#313547',

            '--color-play-button-icon': '#FFF',
            '--color-white-label-text': '#FFF',
            '--color-default-selector-button-bg': '#FFF',
            '--color-convo-card-bg': '#FFF',
            '--color-convo-card-bg-selected': '#EEEFF0',
            '--color-action-bar-bg': '#FFF',
            '--color-meta-bar-bg': '#F5F6F6',
            '--color-wide-bar-bg': '#EEE',
            '--color-dropdown-bg': '#FFF',
            '--color-dropdown-highlight': '#EDF6FF',

            '--color-dialog-header': '#0075E4',
            '--color-dialog-border': '#DDD',
            '--color-dialog-footer': '#EEEFF0',

            '--color-label-text': '#666',
            '--color-alert-text': '#444',
            '--color-tag-text': '#444',
            '--color-tooltip-text': '#FFF',

            '--color-form-border': '#949494',
            '--color-form-placeholder': '#666',
            '--color-form-disabled-text': '#BBB',
            '--color-form-white-bg': '#FFF',
            '--color-form-white-text': '#FFF',
            '--color-form-blue': '#0075E4',

            '--color-form-checkbox-bg': '#FFF',
            '--color-form-checkbox-bg-active': '#DDD',
            '--color-form-checkbox-bg-disabled': '#EEE',
            '--color-form-checkbox-blue': '#0075E4',
            '--color-form-checkbox-blue-active': '#1B5CBF',
            '--color-form-checkbox-blue-disabled': '#CCC',
            '--color-form-checkbox-border': '#949494',
            '--color-form-checkbox-border-hover': '#0075E4',
            '--color-form-checkbox-border-active': '#1B5CBF',
            '--color-form-checkbox-border-disabled': '#DDD',

            '--color-form-on-off-switch-blue': '#0075E4',
            '--color-form-on-off-switch-text': '#444',
            '--color-form-on-off-switch-text-disabled': '#BBB',
            '--color-form-on-off-switch-white-text': '#FFF',
            '--color-form-on-off-switch-handle': '#FFF',
            '--color-form-on-off-switch-handle-active': '#DDD',
            '--color-form-on-off-switch-handle-disabled': '#EEE',

            '--color-slider-bar-bg': '#FFF',
            '--color-slider-bar-bg-disabled': '#EEE',
            '--color-slider-bar-border': '#CCC',
            '--color-slider-bar-border-disabled': '#DDD',
            '--color-slider-blue': '#0075E4',
            '--color-slider-blue-disabled': 'transparent',
            '--color-slider-arrow': '#666',
            '--color-slider-arrow-hover': '#0075E4',
            '--color-slider-arrow-active': '#1B5CBF',
            '--color-slider-arrow-disabled': '#CCC',
            '--color-slider-notch': '#BBB',
            '--color-slider-notch-disabled': '#BBB',
            '--color-slider-thumb-bg': '#FFF',
            '--color-slider-thumb-inner': '#EEE',
            '--color-slider-thumb-inner-hover': '#E2F1FF',
            '--color-slider-thumb-inner-active': '#A5BCD5',
            '--color-slider-thumb-inner-disabled': '#EEE',
            '--color-slider-thumb-border': '#949494',
            '--color-slider-thumb-border-hover': '#0075E4',
            '--color-slider-thumb-border-active': '#1B5CBF',
            '--color-slider-thumb-border-disabled': '#DDD',

            '--color-selector-border': '#CCC',
            '--color-clipboard-selected-text': '#FFF',
            '--color-tag-text-unselected': '#444',
            '--color-clipping-preview-text': '#666',

            '--color-text-highlighter': '#ffebbe'
        }
    },
    'dark-gray': {
        name: 'dark-gray',
        displayName: 'Dark - Gray',
        theme: {
            '--color-scheme': 'dark',

            '--color-base-font-colour': '#E4E4E4',
            '--color-button-text': '#ADADAD',
            '--color-border-1': '#242424',
            '--color-border-2': '#4E4E4E',
            '--color-grey-bg-1': '#1D1D1D',
            '--color-grey-bg-2': '#3E3E3E',
            '--color-white': '#2F2F2F',
            '--color-white-text': '#EEEFF0',
            '--color-white-text-disabled': '#666',
            '--color-white-text-disabled-2': '#666',

            '--color-verint-logo-background': '#3E3E3E',
            '--color-verint-logo-foreground': '#7CACDA',

            '--color-verint-blue': '#7CACDA',
            '--color-green-light': '#3F6340',
            '--color-green-dark': '#123312',
            '--color-highlight': '#3B4146',
            '--color-brown': '#7B6E1A',
            '--color-yellow': '#FFE463',
            '--color-yellow-pale': '#534E3B',

            '--color-assistant-outline': '#5E4A61',
            '--color-assistant-fill': '#5E4A61',
            '--color-assistant-info': '#504052',
            '--color-handover-bot': '#3E4E46',
            '--color-bot-stroke': '#3E4E46',
            '--color-bot-fill': '#3E4E46',
            '--color-handover-agent': '#274D5E',

            '--color-orange': '#CE4100',
            '--color-information': '#49B3DC',
            '--color-dialog-background': 'rgba(0, 29, 44, 0.6)',
            '--color-warning-yellow': '#F2BD25',

            '--color-grey-1': '#EEE',
            '--color-grey-2': '#3E3E3E',
            '--color-grey-3': '#D0D4DA',
            '--color-grey-4': '#242424',
            '--color-grey-5': '#676767',

            '--color-blue-highlight-1': '#E2F1FF',
            '--color-blue-lowlight-1': '#A5BCD5',
            '--color-blue-lowlight-2': '#1B5CBF',
            '--color-blue-focus': '#7CACDA',

            '--color-grey-button': '#3E3E3E',
            '--color-grey-active': '#262626',
            '--color-grey-hover': '#242424',
            '--color-grey-disabled': '#3E3E3E',

            '--color-blue-hover': '#0062BF',
            '--color-blue-active': '#00498F',
            '--color-blue-disabled': '#89B8E4',

            '--color-red': '#ED6161',
            '--color-red-hover': '#B82E29',
            '--color-red-active': '#9E2E2A',
            '--color-red-disabled': '#DC9B99',

            '--color-green': '#3E5C79',
            '--color-green-hover': '#0A6C3C',
            '--color-green-active': '#07512D',
            '--color-green-disabled': '#3F4B57',

            '--color-green-light-hover': '#65C566',
            '--color-green-light-border': '#123312',
            '--color-green-light-disabled': '#A9DAAB',

            '--color-orange-hover': '#E24700',
            '--color-orange-active': '#CE4100',
            '--color-orange-disabled': '#F7A986',

            '--color-dark-blue': '#3E5379',
            '--color-dark-blue-hover': '#163D4E',
            '--color-dark-blue-active': '#103140',
            '--color-dark-blue-disabled': '#A7B7BE',

            '--color-button-yellow': '#D9A000',
            '--color-button-yellow-hover': '#C08D00',
            '--color-button-yellow-active': '#AB7E00',
            '--color-button-yellow-disabled': '#9C8C60',

            '--color-purple': '#8A42A7',
            '--color-purple-hover': '#7F309F',
            '--color-purple-active': '#752794',
            '--color-purple-disabled': '#95839C',

            '--color-tag-red': '#FDD5DA',
            '--color-tag-orange': '#FEEACD',
            '--color-tag-yellow': '#FEFBCB',
            '--color-tag-green': '#DAF8D0',
            '--color-tag-aqua': '#CCFAF4',
            '--color-tag-blue': '#D4E1FF',
            '--color-tag-pink': '#F7DBFC',

            '--color-login-bg': '#313547',
            '--color-grey-bg-1-alpha': 'rgba(237, 237, 237, 0)',

            '--color-play-button-icon': '#E4E4E$',
            '--color-white-label-text': '#FFF',
            '--color-default-selector-button-bg': '#676767',
            '--color-convo-card-bg': '#1D1D1D',
            '--color-convo-card-bg-selected': '#2F2F2F',
            '--color-action-bar-bg': '#2F2F2F',
            '--color-meta-bar-bg': '#242424',
            '--color-wide-bar-bg': '#1D1D1D',
            '--color-dropdown-bg': '#2F2F2F',
            '--color-dropdown-highlight': '#3A3A3A',

            '--color-dialog-header': '#363636',
            '--color-dialog-border': '#363636',
            '--color-dialog-footer': '#262626',

            '--color-label-text': '#ADADAD',
            '--color-alert-text': '#444',
            '--color-tag-text': '#444',

            '--color-form-border': '#949494',
            '--color-form-placeholder': '#666',
            '--color-form-disabled-text': '#666',
            '--color-form-white-bg': '#FFF',

            '--color-form-checkbox-bg': '#BBBBBB',
            '--color-form-checkbox-bg-active': '#666666',
            '--color-form-checkbox-bg-disabled': '#3E3E3E',
            '--color-form-checkbox-blue': '#3B4146',
            '--color-form-checkbox-blue-active': '#3B4146',
            '--color-form-checkbox-blue-disabled': '#CCC',
            '--color-form-checkbox-border': '#949494',
            '--color-form-checkbox-border-hover': '#949494',
            '--color-form-checkbox-border-active': '#949494',
            '--color-form-checkbox-border-disabled': '#DDD',

            '--color-form-on-off-switch-blue': '#7CACDA',
            '--color-form-on-off-switch-text': '#444444',
            '--color-form-on-off-switch-text-disabled': '#BBB',
            '--color-form-on-off-switch-white-text': '#FFF',
            '--color-form-on-off-switch-handle': '#EEEFF0',
            '--color-form-on-off-switch-handle-active': '#DDD',
            '--color-form-on-off-switch-handle-disabled': '#EEE',

            '--color-slider-bar-bg': '#ADADAD',
            '--color-slider-bar-bg-disabled': '#3E3E3E',
            '--color-slider-bar-border': '#676767',
            '--color-slider-bar-border-disabled': '#3E3E3E',
            '--color-slider-blue': '#7CACDA',
            '--color-slider-blue-disabled': 'transparent',
            '--color-slider-arrow': '#ADADAD',
            '--color-slider-arrow-hover': '#7CACDA',
            '--color-slider-arrow-active': '#1B5CBF',
            '--color-slider-arrow-disabled': '#3E3E3E',
            '--color-slider-notch': '#676767',
            '--color-slider-notch-disabled': '#3E3E3E',
            '--color-slider-thumb-bg': '#FFF',
            '--color-slider-thumb-inner': '#EEE',
            '--color-slider-thumb-inner-hover': '#E2F1FF',
            '--color-slider-thumb-inner-active': '#A5BCD5',
            '--color-slider-thumb-inner-disabled': '#EEE',
            '--color-slider-thumb-border': '#949494',
            '--color-slider-thumb-border-hover': '#7CACDA',
            '--color-slider-thumb-border-active': '#1B5CBF',
            '--color-slider-thumb-border-disabled': '#DDD',

            '--color-selector-border': '#242424',
            '--color-clipboard-selected-text': '#2F2F2F',
            '--color-tag-text-unselected': '#ADADAD',
            '--color-clipping-preview-text': '#ADADAD',

            '--color-text-highlighter': '#3E5C79'
        }
    },
    'dark-blue': {
        name: 'dark-blue',
        displayName: 'Dark - Blue',
        theme: {
            '--color-scheme': 'dark',

            '--color-base-font-colour': '#E4E4E4',
            '--color-button-text': '#9DA9BE',
            '--color-border-1': '#0D182E',
            '--color-border-2': '#4E4E4E',
            '--color-grey-bg-1': '#0C172D',
            '--color-grey-bg-2': '#303D56',
            '--color-white': '#19253B',
            '--color-white-text': '#FFF',
            '--color-white-text-disabled': '#666',
            '--color-white-text-disabled-2': '#666',

            '--color-verint-logo-background': '#303D56',
            '--color-verint-logo-foreground': '#7CACDA',
            '--color-verint-blue': '#7CACDA',
            '--color-green-light': '#3F6340',
            '--color-green-dark': '#459E45',
            '--color-highlight': '#253450',
            '--color-brown': '#7B6E1A',
            '--color-yellow': '#FFE463',
            '--color-yellow-pale': '#534E3B',

            '--color-assistant-outline': '#5E4A61',
            '--color-assistant-fill': '#5E4A61',
            '--color-assistant-info': '#504052',
            '--color-handover-bot': '#3E4E46',
            '--color-bot-stroke': '#3E4E46',
            '--color-bot-fill': '#3E4E46',
            '--color-handover-agent': '#274D5E',

            '--color-orange': '#EF540D',
            '--color-information': '#49B3DC',
            '--color-dialog-background': 'rgba(0, 29, 44, 0.6)',
            '--color-warning-yellow': '#F2BD25',

            '--color-grey-1': '#EEE',
            '--color-grey-2': '#282F3E',
            '--color-grey-3': '#7CACDA',
            '--color-grey-4': '#0D182E',
            '--color-grey-5': '#676767',

            '--color-blue-highlight-1': '#E2F1FF',
            '--color-blue-lowlight-1': '#A5BCD5',
            '--color-blue-lowlight-2': '#1B5CBF',
            '--color-blue-focus': '#3395FF',

            '--color-grey-button': '#303D56',
            '--color-grey-hover': '#31475C',
            '--color-grey-disabled': '#303D56',
            '--color-grey-active': '#354351',

            '--color-blue-hover': '#0062BF',
            '--color-blue-active': '#00498F',
            '--color-blue-disabled': '#89B8E4',

            '--color-red': '#D02E28',
            '--color-red-hover': '#B82E29',
            '--color-red-active': '#9E2E2A',
            '--color-red-disabled': '#DC9B99',

            '--color-green': '#3E5C79',
            '--color-green-hover': '#0A6C3C',
            '--color-green-active': '#07512D',
            '--color-green-disabled': '#3F4B57',

            '--color-green-light-hover': '#65C566',
            '--color-green-light-border': '#123312',
            '--color-green-light-disabled': '#A9DAAB',

            '--color-orange-hover': '#E24700',
            '--color-orange-active': '#CE4100',
            '--color-orange-disabled': '#F7A986',

            '--color-dark-blue': '#224A5C',
            '--color-dark-blue-hover': '#163D4E',
            '--color-dark-blue-active': '#103140',
            '--color-dark-blue-disabled': '#A7B7BE',

            '--color-button-yellow': '#D9A000',
            '--color-button-yellow-hover': '#C08D00',
            '--color-button-yellow-active': '#AB7E00',
            '--color-button-yellow-disabled': '#F0D999',

            '--color-purple': '#8A42A7',
            '--color-purple-hover': '#7F309F',
            '--color-purple-active': '#752794',
            '--color-purple-disabled': '#95839C',

            '--color-tag-red': '#FDD5DA',
            '--color-tag-orange': '#FEEACD',
            '--color-tag-yellow': '#FEFBCB',
            '--color-tag-green': '#DAF8D0',
            '--color-tag-aqua': '#CCFAF4',
            '--color-tag-blue': '#D4E1FF',
            '--color-tag-pink': '#F7DBFC',

            '--color-login-bg': '#313547',
            '--color-grey-bg-1-alpha': 'rgba(237, 237, 237, 0)',

            '--color-play-button-icon': '#ADADAD',
            '--color-white-label-text': '#FFF',
            '--color-default-selector-button-bg': '#303D56',
            '--color-convo-card-bg': '#0C172D',
            '--color-convo-card-bg-selected': '#19253B',
            '--color-action-bar-bg': '#19253B',
            '--color-meta-bar-bg': '#19253B',
            '--color-wide-bar-bg': '#0C172D',
            '--color-dropdown-bg': '#19253B',
            '--color-dropdown-highlight': '#3A3A3A',

            '--color-dialog-header': '#303D56',
            '--color-dialog-border': '#303D56',
            '--color-dialog-footer': '#0C172D',

            '--color-label-text': '#9DA9BE',
            '--color-alert-text': '#444',
            '--color-tag-text': '#444',

            '--color-form-border': '#949494',
            '--color-form-placeholder': '#666',
            '--color-form-disabled-text': '#BBB',
            '--color-form-white-bg': '#FFF',

            '--color-form-checkbox-bg': '#9DA9BE',
            '--color-form-checkbox-bg-active': '#DDD',
            '--color-form-checkbox-bg-disabled': '#EEE',
            '--color-form-checkbox-blue': '#303D56',
            '--color-form-checkbox-blue-active': '#566684',
            '--color-form-checkbox-blue-disabled': '#CCC',
            '--color-form-checkbox-border': '#74839F',
            '--color-form-checkbox-border-hover': '#74839F',
            '--color-form-checkbox-border-active': '#74839F',
            '--color-form-checkbox-border-disabled': '#DDD',

            '--color-form-on-off-switch-blue': '#0075E4',
            '--color-form-on-off-switch-text': '#444',
            '--color-form-on-off-switch-text-disabled': '#BBB',
            '--color-form-on-off-switch-white-text': '#FFF',
            '--color-form-on-off-switch-handle': '#FFF',
            '--color-form-on-off-switch-handle-active': '#DDD',
            '--color-form-on-off-switch-handle-disabled': '#EEE',

            '--color-slider-bar-bg': '#ADADAD',
            '--color-slider-bar-bg-disabled': '#3E3E3E',
            '--color-slider-bar-border': '#676767',
            '--color-slider-bar-border-disabled': '#3E3E3E',
            '--color-slider-blue': '#7CACDA',
            '--color-slider-blue-disabled': 'transparent',
            '--color-slider-arrow': '#ADADAD',
            '--color-slider-arrow-hover': '#7CACDA',
            '--color-slider-arrow-active': '#1B5CBF',
            '--color-slider-arrow-disabled': '#3E3E3E',
            '--color-slider-notch': '#676767',
            '--color-slider-notch-disabled': '#3E3E3E',
            '--color-slider-thumb-bg': '#FFF',
            '--color-slider-thumb-inner': '#EEE',
            '--color-slider-thumb-inner-hover': '#E2F1FF',
            '--color-slider-thumb-inner-active': '#A5BCD5',
            '--color-slider-thumb-inner-disabled': '#EEE',
            '--color-slider-thumb-border': '#949494',
            '--color-slider-thumb-border-hover': '#7CACDA',
            '--color-slider-thumb-border-active': '#1B5CBF',
            '--color-slider-thumb-border-disabled': '#DDD',

            '--color-selector-border': '#242424',
            '--color-clipboard-selected-text': '#19253B',
            '--color-tag-text-unselected': '#ADADAD',
            '--color-clipping-preview-text': '#ADADAD',

            '--color-text-highlighter': '#3E5C79'
        }
    }
};

export default themes;
