import React from 'react';

import styles from '../styles/Logo.module.scss';

const Logo = () => (
    <a href="/" id="nav-bar-logo" className={styles['logo']}>
        <svg
            className={styles['logo-svg']}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 88 15"
        >
            <path d="M0,0h3.8l3.8,9.8h0L11.4,0H15L8.7,15H6.1L0,0z" />
            <path d="M18.7,0H29v3h-7v2.8h6.6v3H22v3h7.4v3H18.7V0z" />
            <path
                d="M36.8,6.2h2c0.3,0,0.6,0,1,0c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.3,0.3-0.6,0.3-0.9c0-0.3-0.1-0.6-0.2-0.9
	c-0.2-0.2-0.4-0.4-0.6-0.5c-0.3-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6-0.1-0.9-0.1h-2.2L36.8,6.2L36.8,6.2z M33.5,0h5.9
	c0.7,0,1.5,0.1,2.2,0.2c0.6,0.1,1.3,0.4,1.8,0.7c0.5,0.4,1,0.8,1.2,1.4c0.3,0.7,0.5,1.4,0.5,2.2c0,1-0.3,1.9-0.8,2.7
	c-0.6,0.8-1.5,1.3-2.4,1.4l3.9,6.3h-4l-3.2-6h-1.7v6h-3.3V0z"
            />
            <rect x="49.5" width="3.3" height="15" />
            <path d="M58,0h4.5L69,10.4H69V0h3.3v15H68L61.4,4.3h0V15H58V0z" />
            <path d="M80.3,2.9H76V0h12v2.9h-4.3V15h-3.3L80.3,2.9z" />
            <path
                d="M86.8,13.9H87c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1l0,0c0,0,0,0-0.1,0h-0.2V13.9z M86.5,13.4h0.5
	c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
	c0,0-0.1,0-0.1,0l0.3,0.4h-0.3l-0.3-0.4h0v0.4h-0.3L86.5,13.4L86.5,13.4z M86.2,14c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3
	c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3
	c0.1-0.2,0.1-0.4,0-0.6c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1
	c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.3C86.2,13.7,86.2,13.8,86.2,14 M85.9,14c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.3-0.1,0.6-0.1,0.8,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3
	c0.1,0.3,0.1,0.6,0,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.1-0.6,0.1-0.8,0c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.1-0.1-0.2-0.2-0.2-0.3C85.9,14.2,85.9,14.1,85.9,14"
            />
        </svg>
        <p className={styles['logo-title']}>Agent Workspace</p>
    </a>
);

export default Logo;
