import React from 'react';
import classNames from 'classnames/bind';

import { Config } from '../types';
import styles from '../styles/App.module.scss';

const cx = classNames.bind(styles);

type Props = Pick<Config, 'loggedInAs' | 'email'>;

const ProfileEmail = (props: Props) => {
    const onClick = () => {
        const url = `${window.location.origin}/command/loginas/?user=${props.email}&next=${window.location.pathname}`;
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.value = url;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        console.log('copied', url);
    };
    return (
        <li
            className={cx('profile-email', 'nav-item', {
                'logged-in-as': props.loggedInAs
            })}
            onClick={props.loggedInAs ? onClick : () => {}}
            title={props.email}
        >
            <p>{props.email}</p>
        </li>
    );
};

export default ProfileEmail;
