export interface PendoCredentials {
    visitorId: string;
    accountId: string;
    domainName: string;
    domainType: string;
    clientId: string;
}

export const pendoInitialize = (theme?: string): void => {
    const init = (): void => {
        if (window?.pendo?.isReady?.() === false) {
            window.pendo.initialize({
                visitor: {
                    id: window.pendoCredentials.visitorId,
                    CNVS_AW_theme: theme,
                    CNVS_AW_resolution_screen: `${window.screen.width}x${window.screen.height}`,
                    CNVS_AW_resolution_inner: `${window.innerWidth}x${window.innerHeight}`,
                    CNVS_AW_resolution_details: `screen: ${window.screen.width}x${window.screen.height} - browser outer: ${window.outerWidth}x${window.outerHeight} - browser inner: ${window.innerWidth}x${window.innerHeight} - pixel ratio: ${window.devicePixelRatio}`
                },
                account: {
                    id: window.pendoCredentials.accountId,
                    accountName: window.pendoCredentials.domainName,
                    domainType: window.pendoCredentials.domainType,
                    sfUUID: window.pendoCredentials.clientId,
                    productshortCode: 'CNVS'
                }
            });
        } else {
            console.warn('Pendo script not available');
        }
    };
    const readyStateEventListener = (): void => {
        if (document.readyState === 'complete') {
            init();
            document.removeEventListener(
                'readystatechange',
                readyStateEventListener
            );
        }
    };
    if (
        window.pendoCredentials &&
        window.pendoCredentials.accountId &&
        window.pendoCredentials.visitorId
    ) {
        if (document.readyState === 'complete') {
            init();
        } else {
            document.addEventListener(
                'readystatechange',
                readyStateEventListener
            );
        }
    } else {
        console.log('Pendo credentials not available');
    }
};
