import React from 'react';
import classNames from 'classnames/bind';

import { Config } from '../types';
import Logo from './Logo';
import ProfileEmail from './ProfileEmail';
import styles from '../styles/App.module.scss';
import { pendoInitialize } from '../utils/pendo';

import themes, { DEFAULT_THEME } from '../utils/themes';

const cx = classNames.bind(styles);

interface Props extends Config {}

class App extends React.Component<Props> {
    componentDidMount() {
        pendoInitialize(this.props.agentWorkspaceTheme);
        if (window.agentWorkspaceTheme && themes) {
            window.agentWorkspaceTheme.saveTheme(
                themes[this.props.agentWorkspaceTheme || DEFAULT_THEME]
            );
        }
    }

    private renderLinks() {
        if (!this.props.isAuthenticated) {
            return null;
        }
        return (
            <React.Fragment>
                {this.props.features.queuesWorkflow && (
                    <li
                        className={cx('nav-item', {
                            active: this.props.section === 'queues'
                        })}
                    >
                        <a href="/queues/" id="nav-bar-queues">
                            Conversations
                        </a>
                    </li>
                )}
                {!this.props.features.queuesWorkflow && (
                    <li
                        className={cx('nav-item', {
                            active: this.props.section === 'conversations'
                        })}
                    >
                        <a href="/conversations/" id="nav-bar-conversations">
                            Conversations
                        </a>
                    </li>
                )}
                {this.props.features.canViewOutbound && (
                    <li
                        className={cx('nav-item', {
                            active: this.props.section === 'outbound'
                        })}
                    >
                        <a href="/outbound/" id="nav-bar-outbound">
                            Outbound
                        </a>
                    </li>
                )}
                {this.props.features.managerDashboard &&
                    this.props.features.canViewManagementDashboard && (
                        <li
                            className={cx('nav-item', {
                                active: this.props.section === 'dashboard'
                            })}
                        >
                            <a
                                href="/management-dashboard/"
                                id="nav-bar-management-dashboard"
                            >
                                Dashboard
                            </a>
                        </li>
                    )}
                {this.props.features.canViewAnalytics && (
                <li
                    className={cx('nav-item', {
                        active: this.props.section === 'new_analytics'
                    })}
                >
                    <a href="/analytics/" id="nav-bar-analytics">
                        Analytics
                    </a>
                </li>
            )}
                {this.props.features.canViewAgentDashboard && (
                    <li
                        className={cx('nav-item', {
                            active: this.props.section === 'agent_dashboard'
                        })}
                    >
                        <a
                            href="/agent-dashboard/"
                            id="nav-bar-agent-dashboard"
                        >
                            Agent Dashboard
                        </a>
                    </li>
                )}
                {this.props.features.canViewRealtimeAnalytics && (
                    <li
                        className={cx('nav-item', {
                            active: this.props.section === 'rt_analytics'
                        })}
                    >
                        <a href="/analytics/realtime/" id="nav-bar-realtime">
                            Real Time (Beta)
                        </a>
                    </li>
                )}
            </React.Fragment>
        );
    }

    private renderRight() {
        if (!this.props.isAuthenticated) {
            return null;
        }
        return (
            <React.Fragment>
                <ul className={cx('main-nav-bar', 'main-nav-right')}>
                    <li
                        className={cx('nav-item', {
                            active: this.props.section === 'account'
                        })}
                    >
                        <a href="/account/" id="nav-bar-account">
                            Account
                        </a>
                    </li>
                    <li className={cx('nav-item')}>
                        <a
                            href="https://connect.verint.com/p/createcase"
                            id="nav-bar-support"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Support
                        </a>
                    </li>
                    <ProfileEmail
                        loggedInAs={this.props.loggedInAs}
                        email={this.props.email}
                    />
                </ul>
                <ul className={cx('main-nav-bar')}>
                    <li className={cx('nav-item', 'profile-logout')}>
                        <a
                            href="/logout/"
                            id="nav-bar-logout"
                            className="icon-logout-1"
                        >
                            <span className={cx('screen-reader')}>Log Out</span>
                        </a>
                    </li>
                </ul>
            </React.Fragment>
        );
    }

    render(): JSX.Element {
        return (
            <div
                className={`${cx(
                    'main-nav'
                )} navigation-bar-main-nav-static-class-name`}
            >
                <Logo />
                <div className={cx('top-row')}>
                    <ul className={cx('main-nav-bar')}>{this.renderLinks()}</ul>
                    {this.renderRight()}
                </div>
            </div>
        );
    }
}

export default App;
